<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }}) </small>
        {{ id ? object.name : "" }}
        <br />
        Оформление заказа на закуп
        <v-btn @click="onClickBtnOrder" :color="!orderData.id && !isNewOrder ? 'red' : 'primary'">Выбрать заказ</v-btn>
      </template>
      <v-row>
        <br />
        <v-col cols="12" class="pb-0">
          <v-switch color="blue" v-model="isNewOrder" label="Создать новый заказ на закуп" />
          <v-simple-table dense v-if="!isNewOrder">
            <tbody>
              <tr v-for="(el, i) in mInfo" :key="i">
                <td class="text-left" style="font-weight: 500; height: 38px">{{ el.title }}:</td>
                <td class="text-left" style="height: 38px">
                  <component v-if="el.type" :is="'a-view-' + el.type" :model="el" :values="orderData" :value="orderData[el.name]"></component>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()" :title="'Оформить'"></a-btn-save>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <v-dialog v-if="showSelectOrderDialog" v-model="showSelectOrderDialog" persistent :retain-focus="false" width="800">
      <v-card>
        <v-card-title>
          <span>Выбор заказа на закуп</span>
        </v-card-title>
        <v-card-text style="height: 250px; overflow: auto">
          <a-table-f-api
            @click="onSelectOrder($event)"
            ref="tableOrder"
            api="/accounting/doc/order_good"
            :model="$store.getters['config/get'].models.documentOrderGoods.list.filter((el) => ['id', 'vendor_id', 'date_doc', 'code_doc', ''].includes(el.name))"
            :searchable="false"
            :defaults="{
              filters: {
                object_code: object.object_id,
                operation_type: 1,
                status: 1,
                createdby_id: $root.profile.id,
              },
              sort: { key: 'id', order: 'DESC' },
              paramName: 'objectReport',
            }"
          >
          </a-table-f-api>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="onCloseSelectOrder">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl } from "../../components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl],

  props: {
    value: Boolean,
    id: Number,
    object: Object,
  },
  data() {
    return {
      showSelectOrderDialog: false,
      isNewOrder: false,
      api: "/accounting/doc/order_good",
      //model: [{ name: "price", title: "Стоимость покупки", type: "number" }],
      m: this.$store.getters["config/get"].models.documentOrderGoods,

      parent: null,
      orderNew: { type: 1, vendor_id: null, createdon: "" },
      orderData: { type: 1, vendor_id: null, createdon: null },
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.formDetail));
      model.forEach((el) => {
        if (!"price_plan,price_real,category_id,status".split(",").includes(el.name)) {
          el.hidden = true;
          el.validator = [];
        }
        if (["price_plan"].includes(el.name)) {
          el.readonly = true;
        }
        if (el?.sourceList) {
          //если список вариантов находится во внешнем массиве, присоединяю его
          el.options = this.md?.[el.sourceList]
            ? this.m[el.sourceList].filter((list) => {
                return !list?.hidden;
              })
            : [];
        }
      });
      return model;
    },
    mInfo() {
      return [
        { name: "id", title: "Номер заказа на закуп", type: "string" },
        { name: "createdon", title: "Дата", type: "datetime" },
        { name: "object_code", title: "Объект", type: "select", dir: "object" },
        {
          name: "vendor_id",
          title: "Поставщик",
          type: "select",
          dir: "vendor",
        },
      ];

      return this.$store.getters["config/get"].models.documentOrderGoods.form1.filter((el) => ["createdon,vendor_id"].includes(el.name));
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.init_();
      }
    },
    isNewOrder(v) {
      if (!v) {
        this.showSelectOrderDialog = true;
      } else {
        this.orderData = this.orderNew;
      }
    },
    "parent.price"(v) {
      if (v) {
        this.data.price_plan = v;
      }
    },
    "parent.category_id"(v) {
      if (v) {
        this.data.category_id = v;
      }
    },
  },
  created() {
    this.init_();
    //  this.showSelectOrderDialog = !false;
  },
  methods: {
    async init_() {
      console.log("init");
      await this.fetchParent(this.object.params);
      const d = new Date();

      let data = {
        name: "Покупка по чеклисту #" + this.object.params.parent_id + ". " + this.object.name,
        object_id: this.object.object_id,
        date_doc: d.toISOString(),
        params: {
          parent: this.object.params.parent,
          parent_id: this.object.params.parent_id,
        },
      };
      this.data = Object.assign(this.data, data);
    },
    onClickBtnOrder() {
      if (this.isNewOrder) {
        this.isNewOrder = false;
      } else {
        this.showSelectOrderDialog = true;
      }
    },
    onCloseSelectOrder() {
      if (!this.orderData.id) {
        this.isNewOrder = true;
      }
      this.showSelectOrderDialog = false;
    },
    async onSelectOrder(e) {
      //получаю с сервера полные данные выбранного заказа
      let response = await this.$axios.get(this.api + "/" + e.row.id);
      let status = response.data.status == "ok";
      if (status) {
        this.orderData = response.data.data;
        this.showSelectOrderDialog = false;
      } else this.error("Ошибка в получения данных заказа на закуп");
    },
    async fetchParent({ parent, parent_id }) {
      if (parent == "construction-goods") {
        let response = await this.$axios.get("/mechti/" + parent + "/" + parent_id);
        let status = response.data.status == "ok";
        if (status) {
          console.log("parent", response.data.data);
          this.parent = response.data.data;
        } else this.error("Ошибка в получения данных связанного объекта");
      }
    },
    async submit() {
      if (!this.parent.id) {
        this.error("Ошибка в данных связанного объекта");
        return;
      }
      let data_table_new = {
        good_id: this.parent.id,
        category_id: this.data.category_id,
        name: this.parent.name,
        amount: this.parent.amount,
        amount_real: this.parent.amount,
        measure: this.parent.measure,
        price_plan: this.parent.price || this.data.price_real,
        price_real: this.data.price_real,
        status: 0,
      };
      for (const name in data_table_new) {
        this.$set(this.data, name, data_table_new[name]);
      }
      if (await this.validateAll(this.data)) {
        let data;
        if (this.isNewOrder) {
          data = {
            type: 1,
            status: 1,
            operation_type: 1,
            object_code: this.object.object_id,
            data_table: [data_table_new],
          };
        } else {
          if (!this.orderData.id) {
            this.error("Ошибка в данных документа связанного объекта");
            return;
          }
          console.log("orderData", this.orderData);
          let data_table = this.orderData.data_table;
          data_table.push(data_table_new);
          data = {
            id: this.orderData.id,
            data_table,
          };
        }
        await this.post(data, this.api);
        return true;
      } else {
        console.log(this.errors);
      }
      return false;
    },
    error(e) {
      this.$root.$emit("show-info", {
        type: "error",
        text: e,
      });
    },
    async post(data, api) {
      if (!this.id) return;
      //const data = {};

      //return;
      console.log("buy", api, data);
      this.loading = true;
      let response = await this.$axios.post(api, data, {});
      this.loading = false;
      let status = response.data.status == "ok";
      if (status) {
        this.$root.$emit("show-info", {
          text: "Отправлено в заказ",
        });
      }
      this.afterSave(data, status);
    },

    afterSave(data, status) {
      if (status) {
        this.$emit("input");
        this.$emit("refresh");
      }
    },
  },
};
</script>
